<!--  -->
<template>
  <div class="main has-header">
    <mt-header fixed title="流水明细">
      <router-link to slot="left">
        <mt-button icon="back" @click.native="goBack"></mt-button>
      </router-link>
    </mt-header>
    <div class="info">
      <div class="total-info">
        <p class="total-info-title">总金额（元）</p>
        <p class="total-info-value">{{turnoverAmt || 0}}</p>
      </div>
      <div class="list" v-for="turnover in this.turnoverList" :key="turnover.month">
        <p class="list-title">{{turnover.month}}</p>
        <div
          class="list-info"
          @click="handleShowReceipt(detail.voucherOss)"
          v-for="detail in turnover.list"
          :key="detail.txId"
        >
          <img :src="hanldeGetLable(detail.bankCode)" alt="money" class="money" />
          <div class="list-info-detail">
            <p class="list-info-detail-label">{{hanldeGetDesensitization(detail.userAccount)}}</p>
            <p class="list-info-detail-time">{{detail.strCreateTime}}</p>
          </div>
          <p class="list-info-value">{{detail.amt}}</p>
          <img src="@assets/images/user/arrow.png" alt="arrow" class="arrow" />
        </div>
      </div>
      <p v-if="turnoverList.length > 0" class="list-label">没有更多啦~</p>
      <p v-else class="list-label">你还没有流水明细~</p>
    </div>
  </div>
</template>

<script>
import { getTurnoverList } from "@api/user";
import { Toast } from "mint-ui";
export default {
  components: {},
  data() {
    //这里存放数据
    return {
      turnoverList: [],
      turnoverAmt: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goBack() {
      this.$router.back(-1);
    },
    handleShowReceipt(url) {
      if (!url) {
        Toast({
          message: "暂无回单，请稍后查看",
          duration: 2000,
        });
        return;
      }
      window.location.href = url;
    },
    handleGetTurnoverList() {
      getTurnoverList()
        .then((res) => {
          if (res.code === 200) {
            this.turnoverList = res.data.h5PaymentDetailVOList || [];
            this.turnoverAmt = res.data.totalAmt;
          }
        })
        .catch((err) => {});
    },
    hanldeGetLable(code) {
      if (!code) {
        code = "default";
      }
      let url = `https://xiyk.oss-cn-hangzhou.aliyuncs.com/bank-logo/${code}.png`;
      return url;
    },
    hanldeGetDesensitization(param) {
      let strlen = param.length;
      if (strlen <= 8) {
        return strlen;
      }
      return param.replace(/^(.{4})(?:\d+)(.{4})$/, "$1********$2");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.handleGetTurnoverList();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.info {
  margin-bottom: 120px;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
.total-info {
  width: 100%;
  height: 30.4vw;
  background: linear-gradient(0deg, rgba(255, 46, 81, 1), rgba(255, 51, 92, 1));
  text-align: center;
  color: #ffffff;
  padding-top: 10vw;

  &-title {
    margin-bottom: 2.4vw;
    font-size: 3.7333vw;
    font-weight: 500;
    opacity: 0.8;
  }

  &-value {
    font-size: 6.4vw;
    font-weight: 500;
  }
}
.list-title {
  width: 100%;
  height: 10.4vw;
  line-height: 10.4vw;
  background-color: #f5f5f5;
  font-size: 4.2667vw;
  font-weight: 500;
  padding-left: 5.8667vw;
}
.list-label {
  text-align: center;
  margin-top: 0.8vw;
  font-size: 2vw;
  color: #cccccc;
}
.list-info {
  white-space: nowrap;
  background-color: #ffffff;
  padding: 3.4667vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2667vw solid #f5f5f5;
  width: 100%;
  height: 17.3333vw;

  .money {
    width: 10.6667vw;
    height: 10.6667vw;
    display: block;
    margin-right: 4vw;
  }

  .arrow {
    width: 5vw;
    height: 5vw;
    display: block;
    margin-left: 1vw;
  }

  &-detail {
    width: 53.3333vw;

    &-label {
      font-size: 4.2667vw;
      font-weight: 500;
      color: rgba(53, 54, 56, 1);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-time {
      font-size: 3.2vw;
      font-weight: 400;
      color: rgba(155, 156, 158, 1);
    }
  }

  &-value {
    flex-grow: 1;
    text-align: right;
    line-height: 10.4vw;
    height: 10.4vw;
    font-size: 4.8vw;
    font-weight: bold;
    color: rgba(53, 54, 56, 1);
  }
}
</style>